.travel_navigation {
  height: 26px;
  line-height: 26px;
  width: 100%;
  margin-top: 10px;

  .previous_element,
  .next_element {
    display: inline-block;
    width: 50%;
  }
  .previous_element {
    text-align: left;

    a {
      padding-right: 10px;

      .icon {
        margin-left: 5px;
      }
    }
  }

  .next_element {
    text-align: right;

    a {
      padding-left: 10px;
      .icon {
        margin-right: 5px;
      }
    }
  }
}
