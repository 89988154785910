.menu {
  border-bottom: 1px solid #8fb1dc;
  height: 30px;
  margin-bottom: 10px;
  padding: 0px 10px 7px 10px;

  a {
    padding: 2px 10px 2px 0px;
    margin-right: 12px;
  }
}
