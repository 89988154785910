.entry_content {
  clear: both;
  margin-bottom: 10px;
  padding: 5px 10px 0px 10px;
  text-align: justify;

  blockquote {
    border-left: solid 4px #8fb1dc;
    font-style: italic;
    padding-left: 5px;
  }

  a {
    color: #0e63cc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  em {
    display: block;
    font-style: italic;
    text-align: center;
    margin: 10px 0px 10px 0px;
  }

  h2 {
    color: #fcfdff;
    background-color: #8fb1dc;
    margin-top: 10px;
    padding: 5px 15px 5px 15px;

    a {
      color: #fcfdff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: url(../imgs/icon-popout.png);
        margin-left: 5px;
      }
    }
  }

  h3 {
    font-style: italic;
    margin: 35px 10px 5px 25px;
  }

  img {
    background-color: white;
    border: solid 1px #a9a9a9;
    padding: 3px;
    margin: 10px 0px 5px 0px;
    max-width: 500px;
  }

  p > a > img {
    float: left;
    margin: 0px 1em 10px 0px;
  }

  ul {
    padding-left: 25px;
  }
}
