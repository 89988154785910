a.icon_link_with_text {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  border: 1px solid transparent;
  vertical-align: middle;

  &:hover {
    border: 1px dotted #a9a9a9;
  }
}
