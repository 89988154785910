.country-change-header {
  font-size: large;
  font-weight: bold;
  line-height: 14px;
  margin: 10px 0 15px 0;
  text-align: center;

  .country_flag {
    margin-left: 15px;
    margin-right: 15px;
  }

  span.datetime {
    display: block;
    padding-left: 0;
  }
}
