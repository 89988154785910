.user-profile {
  .user-travels {
    list-style: none;
    padding-left: 1em;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
