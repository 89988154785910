h2.travel-header {
  font-size: 14px;
  margin: 0 0 5px 0;
  color: unset;

  a.travel_name {
    color: #7891b7;
    font-size: large;
    font-weight: bolder;
    font-style: normal;

    &:hover {
      text-decoration: underline;
    }
  }

  .travel_owner {
    font-weight: normal;
    font-size: smaller;
    font-style: italic;

    a {
      text-decoration: underline;
    }
  }
}
