.travel_body {
  margin: 0 20px;

  .description {
    margin-bottom: 10px;
  }

  .travel_countries_summary {
    border: 1px solid #8fb1dc;
    border-width: 1px 0;
    text-align: center;
    margin: 10px 0;
    padding: 10px 0;
  }
}
