.App {
  margin: 20px auto 0px auto;
  width: 850px;
  max-width: 1200px;
}

.header {
  background-image: url('../imgs/metouleuptheworld.png');
  background-position: 25px center;
  background-repeat: no-repeat;
  background-color: #b4cff0;
  border: 1px solid #8796dc;
  border-bottom: none;
  height: 70px;
  padding: 0px 20px 0px 25px;
  position: relative;

  .header__title {
    color: white;
    font-size: xx-large;
    font-style: italic;
    font-weight: bold;
    line-height: 70px;
    text-align: center;
  }
}

.datetime {
  color: #a9a9a9;
  font-size: small;
  font-weight: normal;
  padding-left: 10px;
}

.content {
  background-color: #fcfdff;
  border: 1px solid #8796dc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
}

.footer {
  font-style: italic;
  margin: 10px auto 10px auto;
  text-align: center;
}
