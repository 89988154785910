.travel_map {
  width: 700px;
  height: 300px;
  margin: 0px auto 20px auto;

  &.loading {
    border: solid 1px #333;
    background-color: white;
  }

  &.failed {
    border: solid 1px #333;
    background-color: aliceblue;
  }

  .travel_map_marker {
    display: flex;
    align-items: center;
    margin: 0 0.2em 0 0;

    .location_details {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0 0 0.3em 0;
      }

      .datetime {
        padding: 0;
        font-style: italic;
        font-size: small;
      }
    }
  }
}
