.photo-viewer {
  height: 100vh;
  width: 100vw;
  border-radius: 0px;
  background-color: #333;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;

  &:focus-visible {
    outline: none;
  }

  .photo-viewer-content {
    display: flex;
    flex: 1;
    height: 100%;

    .photo {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      position: relative;

      img {
        padding: 0;
        margin: 0;
        border: none;
        object-fit: contain;
        background-color: #333;
        height: 100%;
        width: 100%;
      }

      .photo_caption {
        font-style: italic;
        background-color: #333;
        padding: 0.6em 1.5em;
        position: absolute;
        bottom: 10px;
        border-radius: 10px;

        .datetime {
          padding-left: 0;
        }

        .photo_title {
          & ~ .datetime {
            padding-left: 10px;
          }
        }
      }

      .show_previous_photo,
      .show_next_photo {
        position: absolute;
        width: 15%;
        height: 100%;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .show_previous_photo {
        left: 0;
      }

      .show_next_photo {
        right: 0;
      }
    }

    .photo_comments {
      background-color: white;
      color: black;
      width: calc(14% + 20px);
      border-left: 1px solid #333;

      .photo_comments_header {
        background-color: #b4cff0;
        border: solid #8796dc;
        border-width: 0 0 1px 1px;
        margin: 0;
        padding: 10px;
        color: white;
        font-style: italic;
      }

      .travel-header,
      .entry_header {
        margin: 0;
        padding: 10px;
        background-color: #f7f8fa;
      }

      .entry_header {
        padding-top: 0;
      }

      .entry_header + .entry_comments,
      .entry_header + .comment_form {
        border: none;
      }

      .comment_form {
        padding: 0 10px;
      }
    }
  }

  &::backdrop {
    background: rgb(143, 177, 220, 0.2);
  }
}
