html {
  box-sizing: content-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  line-height: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* local development */
body {
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
}

a {
  color: #333;
  text-decoration: none;
}

h2 {
  color: #7891b7;
}

img {
  vertical-align: middle;
}

fieldset {
  border: none;

  > input,
  > textarea {
    border: 1px solid #a6c9e2;
    width: calc(100% - 10px);
    padding: 5px;
  }
}
