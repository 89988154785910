@charset "UTF-8";
.country_flag {
  border: 1px solid black;
  background-image: url(../imgs/country_flags.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  margin: 0px 10px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 22px;
}
.A0 {
  background-position: 0 -0px;
  border: none;
}
.AD {
  background-position: 0 -19px;
}
.AE {
  background-position: 0 -34px;
}
.AF {
  background-position: 0 -45px;
}
.AG {
  background-position: 0 -60px;
}
.AI {
  background-position: 0 -75px;
}
.AL {
  background-position: 0 -86px;
}
.AM {
  background-position: 0 -102px;
}
.AN {
  background-position: 0 -113px;
}
.AO {
  background-position: 0 -128px;
}
.AR {
  background-position: 0 -143px;
}
.AS {
  background-position: 0 -157px;
}
.AT {
  background-position: 0 -168px;
}
.AU {
  background-position: 0 -183px;
}
.AW {
  background-position: 0 -194px;
}
.AX {
  background-position: 0 -209px;
}
.AZ {
  background-position: 0 -223px;
}
.BA {
  background-position: 0 -234px;
}
.BB {
  background-position: 0 -245px;
}
.BD {
  background-position: 0 -260px;
}
.BE {
  background-position: 0 -273px;
}
.BF {
  background-position: 0 -288px;
}
.BG {
  background-position: 0 -303px;
}
.BH {
  background-position: 0 -316px;
}
.BI {
  background-position: 0 -329px;
}
.BJ {
  background-position: 0 -342px;
}
.BL {
  background-position: 0 -357px;
}
.BM {
  background-position: 0 -372px;
}
.BN {
  background-position: 0 -383px;
}
.BO {
  background-position: 0 -394px;
}
.BR {
  background-position: 0 -409px;
}
.BS {
  background-position: 0 -424px;
}
.BT {
  background-position: 0 -435px;
}
.BV {
  background-position: 0 -450px;
}
.BW {
  background-position: 0 -466px;
}
.BY {
  background-position: 0 -481px;
}
.BZ {
  background-position: 0 -492px;
}
.CA {
  background-position: 0 -507px;
}
.CC {
  background-position: 0 -518px;
}
.CD {
  background-position: 0 -529px;
}
.CF {
  background-position: 0 -546px;
}
.CG {
  background-position: 0 -561px;
}
.CH {
  background-position: 0 -576px;
}
.CI {
  background-position: 0 -596px;
}
.CK {
  background-position: 0 -611px;
}
.CL {
  background-position: 0 -622px;
}
.CM {
  background-position: 0 -637px;
}
.CN {
  background-position: 0 -652px;
}
.CO {
  background-position: 0 -667px;
}
.CR {
  background-position: 0 -682px;
}
.CU {
  background-position: 0 -695px;
}
.CV {
  background-position: 0 -706px;
}
.CX {
  background-position: 0 -719px;
}
.CY {
  background-position: 0 -730px;
}
.CZ {
  background-position: 0 -743px;
}
.DE {
  background-position: 0 -758px;
}
.DJ {
  background-position: 0 -771px;
}
.DK {
  background-position: 0 -786px;
}
.DM {
  background-position: 0 -803px;
}
.DO {
  background-position: 0 -814px;
}
.DZ {
  background-position: 0 -828px;
}
.EC {
  background-position: 0 -843px;
}
.EE {
  background-position: 0 -854px;
}
.EG {
  background-position: 0 -868px;
}
.ER {
  background-position: 0 -883px;
}
.ES {
  background-position: 0 -894px;
}
.ET {
  background-position: 0 -909px;
}
.FI {
  background-position: 0 -920px;
}
.FJ {
  background-position: 0 -933px;
}
.FK {
  background-position: 0 -944px;
}
.FM {
  background-position: 0 -955px;
}
.FO {
  background-position: 0 -967px;
}
.FR {
  background-position: 0 -983px;
}
.GA {
  background-position: 0 -998px;
}
.GB {
  background-position: 0 -1015px;
}
.GD {
  background-position: 0 -1026px;
}
.GE {
  background-position: 0 -1039px;
}
.GF {
  background-position: 0 -1054px;
}
.GG {
  background-position: 0 -1069px;
}
.GH {
  background-position: 0 -1084px;
}
.GI {
  background-position: 0 -1099px;
}
.GL {
  background-position: 0 -1110px;
}
.GM {
  background-position: 0 -1125px;
}
.GN {
  background-position: 0 -1140px;
}
.GP {
  background-position: 0 -1155px;
}
.GQ {
  background-position: 0 -1170px;
}
.GR {
  background-position: 0 -1185px;
}
.GS {
  background-position: 0 -1200px;
}
.GT {
  background-position: 0 -1211px;
}
.GU {
  background-position: 0 -1225px;
}
.GW {
  background-position: 0 -1238px;
}
.GY {
  background-position: 0 -1249px;
}
.HK {
  background-position: 0 -1262px;
}
.HM {
  background-position: 0 -1277px;
}
.HN {
  background-position: 0 -1288px;
}
.HR {
  background-position: 0 -1299px;
}
.HT {
  background-position: 0 -1310px;
}
.HU {
  background-position: 0 -1323px;
}
.ID {
  background-position: 0 -1334px;
}
.IE {
  background-position: 0 -1349px;
}
.IL {
  background-position: 0 -1360px;
}
.IM {
  background-position: 0 -1376px;
}
.IN {
  background-position: 0 -1387px;
}
.IO {
  background-position: 0 -1402px;
}
.IQ {
  background-position: 0 -1413px;
}
.IR {
  background-position: 0 -1428px;
}
.IS {
  background-position: 0 -1441px;
}
.IT {
  background-position: 0 -1457px;
}
.JE {
  background-position: 0 -1472px;
}
.JM {
  background-position: 0 -1485px;
}
.JO {
  background-position: 0 -1496px;
}
.JP {
  background-position: 0 -1507px;
}
.KE {
  background-position: 0 -1522px;
}
.KG {
  background-position: 0 -1537px;
}
.KH {
  background-position: 0 -1550px;
}
.KI {
  background-position: 0 -1565px;
}
.KM {
  background-position: 0 -1576px;
}
.KN {
  background-position: 0 -1589px;
}
.KP {
  background-position: 0 -1604px;
}
.KR {
  background-position: 0 -1615px;
}
.KW {
  background-position: 0 -1630px;
}
.KY {
  background-position: 0 -1641px;
}
.KZ {
  background-position: 0 -1652px;
}
.LA {
  background-position: 0 -1663px;
}
.LB {
  background-position: 0 -1678px;
}
.LC {
  background-position: 0 -1693px;
}
.LI {
  background-position: 0 -1704px;
}
.LK {
  background-position: 0 -1717px;
}
.LR {
  background-position: 0 -1728px;
}
.LS {
  background-position: 0 -1740px;
}
.LT {
  background-position: 0 -1755px;
}
.LU {
  background-position: 0 -1768px;
}
.LV {
  background-position: 0 -1781px;
}
.LY {
  background-position: 0 -1792px;
}
.MA {
  background-position: 0 -1803px;
}
.MC {
  background-position: 0 -1818px;
}
.MD {
  background-position: 0 -1836px;
}
.ME {
  background-position: 0 -1847px;
}
.MF {
  background-position: 0 -1858px;
}
.MG {
  background-position: 0 -1873px;
}
.MH {
  background-position: 0 -1888px;
}
.MK {
  background-position: 0 -1900px;
}
.ML {
  background-position: 0 -1911px;
}
.MM {
  background-position: 0 -1926px;
}
.MN {
  background-position: 0 -1938px;
}
.MO {
  background-position: 0 -1949px;
}
.MP {
  background-position: 0 -1964px;
}
.MQ {
  background-position: 0 -1975px;
}
.MR {
  background-position: 0 -1990px;
}
.MS {
  background-position: 0 -2005px;
}
.MT {
  background-position: 0 -2016px;
}
.MU {
  background-position: 0 -2031px;
}
.MV {
  background-position: 0 -2046px;
}
.MW {
  background-position: 0 -2061px;
}
.MX {
  background-position: 0 -2076px;
}
.MY {
  background-position: 0 -2089px;
}
.MZ {
  background-position: 0 -2100px;
}
.NA {
  background-position: 0 -2115px;
}
.NC {
  background-position: 0 -2130px;
}
.NE {
  background-position: 0 -2145px;
}
.NF {
  background-position: 0 -2164px;
}
.NG {
  background-position: 0 -2175px;
}
.NI {
  background-position: 0 -2186px;
}
.NL {
  background-position: 0 -2199px;
}
.NO {
  background-position: 0 -2214px;
}
.NP {
  background-position: 0 -2230px;
  border: none;
}
.NR {
  background-position: 0 -2250px;
}
.NU {
  background-position: 0 -2261px;
}
.NZ {
  background-position: 0 -2272px;
}
.OM {
  background-position: 0 -2283px;
}
.PA {
  background-position: 0 -2294px;
}
.PE {
  background-position: 0 -2309px;
}
.PF {
  background-position: 0 -2324px;
}
.PG {
  background-position: 0 -2339px;
}
.PH {
  background-position: 0 -2356px;
}
.PK {
  background-position: 0 -2367px;
}
.PL {
  background-position: 0 -2382px;
}
.PM {
  background-position: 0 -2396px;
}
.PN {
  background-position: 0 -2411px;
}
.PR {
  background-position: 0 -2422px;
}
.PS {
  background-position: 0 -2437px;
}
.PT {
  background-position: 0 -2448px;
}
.PW {
  background-position: 0 -2463px;
}
.PY {
  background-position: 0 -2477px;
}
.QA {
  background-position: 0 -2490px;
}
.RE {
  background-position: 0 -2499px;
}
.RO {
  background-position: 0 -2514px;
}
.RS {
  background-position: 0 -2529px;
}
.RU {
  background-position: 0 -2544px;
}
.RW {
  background-position: 0 -2559px;
}
.SA {
  background-position: 0 -2574px;
}
.SB {
  background-position: 0 -2589px;
}
.SC {
  background-position: 0 -2600px;
}
.SD {
  background-position: 0 -2611px;
}
.SE {
  background-position: 0 -2622px;
}
.SG {
  background-position: 0 -2636px;
}
.SH {
  background-position: 0 -2651px;
}
.SI {
  background-position: 0 -2662px;
}
.SJ {
  background-position: 0 -2673px;
}
.SK {
  background-position: 0 -2689px;
}
.SL {
  background-position: 0 -2704px;
}
.SM {
  background-position: 0 -2719px;
}
.SN {
  background-position: 0 -2736px;
}
.SO {
  background-position: 0 -2751px;
}
.SR {
  background-position: 0 -2766px;
}
.ST {
  background-position: 0 -2781px;
}
.SV {
  background-position: 0 -2792px;
}
.SY {
  background-position: 0 -2804px;
}
.SZ {
  background-position: 0 -2819px;
}
.TC {
  background-position: 0 -2834px;
}
.TD {
  background-position: 0 -2845px;
}
.TF {
  background-position: 0 -2860px;
}
.TG {
  background-position: 0 -2875px;
}
.TH {
  background-position: 0 -2889px;
}
.TJ {
  background-position: 0 -2904px;
}
.TK {
  background-position: 0 -2915px;
}
.TL {
  background-position: 0 -2926px;
}
.TM {
  background-position: 0 -2937px;
}
.TN {
  background-position: 0 -2952px;
}
.TO {
  background-position: 0 -2967px;
}
.TR {
  background-position: 0 -2978px;
}
.TT {
  background-position: 0 -2993px;
}
.TV {
  background-position: 0 -3006px;
}
.TZ {
  background-position: 0 -3017px;
}
.UA {
  background-position: 0 -3032px;
}
.UG {
  background-position: 0 -3047px;
}
.UM {
  background-position: 0 -3062px;
}
.US {
  background-position: 0 -3074px;
}
.UY {
  background-position: 0 -3086px;
}
.UZ {
  background-position: 0 -3101px;
}
.VA {
  background-position: 0 -3112px;
}
.VC {
  background-position: 0 -3132px;
}
.VE {
  background-position: 0 -3147px;
}
.VG {
  background-position: 0 -3162px;
}
.VI {
  background-position: 0 -3173px;
}
.VN {
  background-position: 0 -3188px;
}
.VU {
  background-position: 0 -3203px;
}
.WF {
  background-position: 0 -3216px;
}
.WS {
  background-position: 0 -3231px;
}
.YE {
  background-position: 0 -3242px;
}
.YT {
  background-position: 0 -3257px;
}
.ZA {
  background-position: 0 -3272px;
}
.ZM {
  background-position: 0 -3287px;
}
.ZW {
  background-position: 0 -3302px;
}
.NP {
  width: 16px;
}
.CH,
.VA {
  width: 20px;
}
.QA {
  height: 9px;
}
.AE,
.AI,
.AM,
.AS,
.AU,
.AZ,
.BA,
.BM,
.BN,
.BS,
.BY,
.CA,
.CC,
.CK,
.CU,
.CX,
.DM,
.EC,
.ER,
.ET,
.FJ,
.FK,
.GB,
.GI,
.GS,
.GW,
.HM,
.HN,
.HR,
.HU,
.IE,
.IM,
.IO,
.JM,
.JO,
.KI,
.KP,
.KW,
.KY,
.KZ,
.LC,
.LK,
.LV,
.LY,
.MD,
.ME,
.MK,
.MN,
.MP,
.MS,
.MY,
.NF,
.NG,
.NR,
.NU,
.NZ,
.OM,
.PH,
.PN,
.PS,
.SB,
.SC,
.SD,
.SH,
.SI,
.ST,
.TC,
.TJ,
.TK,
.TL,
.TO,
.TV,
.UZ,
.VG,
.WS,
.ZW {
  height: 11px;
}
.FM,
.LR,
.MH,
.MM,
.SV,
.UM,
.US {
  height: 12px;
}
.BD,
.BG,
.BH,
.BI,
.CR,
.CV,
.CY,
.DE,
.FI,
.GD,
.GU,
.GY,
.HT,
.IR,
.JE,
.KG,
.KM,
.LI,
.LT,
.LU,
.MX,
.NI,
.PY,
.TT,
.VU {
  height: 13px;
}
.AR,
.AX,
.DO,
.EE,
.GT,
.PL,
.PW,
.SE,
.TG {
  height: 14px;
}
.AL,
.BV,
.FO,
.IL,
.IS,
.NO,
.SJ {
  height: 16px;
}
.CD,
.DK,
.GA,
.PG,
.SM {
  height: 17px;
}
.MC {
  height: 18px;
}
.A0,
.NE {
  height: 19px;
}
.CH,
.NP,
.VA {
  height: 20px;
}
