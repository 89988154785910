.country-change {
  ul.posts {
    padding: 0;

    a.link_to_entry .icon {
      margin-left: 5px;
    }
  }

  ~ .travel_navigation {
    padding-top: 12px;
    border-top: 1px solid #8fb1dc;
  }
}
