.entry_comments {
  padding: 10px;
  border-top: 1px solid #8fb1dc;

  .entry_comment {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .datetime {
      color: #a9a9a9;
      font-size: small;
      font-style: italic;
      padding: 0px 0px 2px 5px;
    }
  }

  .comment_author {
    font-size: larger;
    font-weight: bold;
    margin-right: 5px;

    a {
      color: #0e63cc;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .comment_text {
    padding-left: 5px;
  }
}

.comment_form {
  border-top: 1px solid #8fb1dc;
}
