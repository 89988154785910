/* 
    Document   : icons
    Created on : May 13, 2010, 9:58:36 AM
    Author     : metoule
    Description:
        Contains the icons.
*/

/* ******************************
              ICONS
   ****************************** */

/* base style for icons*/
.icon {
  background-image: url(./imgs/icons.png);
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: -1px;
  height: 24px;
  width: 24px;
  background-position: 24px;
  margin: 0 10px;
  vertical-align: bottom;
}

/* specific positioning */
.icon_home {
  background-position: -10px 0px;
}
.icon_login {
  background-position: -34px 0px;
}
.icon_register {
  background-position: -58px 0px;
}
.icon_logout {
  background-position: -82px 0px;
}
.icon_photos {
  background-position: -106px 0px;
}
.icon_myinfo {
  background-position: -130px 0px;
}
.icon_add {
  background-position: -154px 0px;
}
.icon_changeCountry {
  background-position: -178px 0px;
}
.icon_previous {
  background-position: -202px 0px;
}
.icon_next {
  background-position: -226px 0px;
}
.icon_refresh {
  background-position: -250px 0px;
}
.icon_remove {
  background-position: -274px 0px;
}
.icon_comments {
  background-position: -298px 0px;
}
.icon_edit {
  background-position: -322px 0px;
}
.icon_read {
  background-position: -346px 0px;
}
.icon_unread {
  background-position: -370px 0px;
}
.icon_entry {
  background-position: -394px 0px;
}
.icon_changeLocation {
  background-position: -418px 0px;
}
.icon_ROAD {
  background-position: -442px 0px;
}
.icon_PLANE {
  background-position: -466px 0px;
}
.icon_TRAIN {
  background-position: -490px 0px;
}
.icon_BOAT {
  background-position: -514px 0px;
}
.icon_travel {
  background-position: -538px 0px;
}
.icon_notification {
  background-position: -562px 0px;
}
.icon_profile {
  background-position: -584px 0px;
}
.icon_notifBulle {
  background-position: -608px 0px;
}
.icon_greenbar {
  background-position: -634px 0px;
  height: 14px;
  width: 11px;
}
.icon_redbar {
  background-position: -645px 0px;
  height: 14px;
  width: 11px;
}

/* notification icons */
.icon_notif_entryComment {
  background-position: -298px 0px;
}
.icon_notif_photoComment {
  background-position: -106px 0px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-width: 4px !important;
  border-radius: 50%;
  border-top: 16px solid #7891b7;
  width: 16px;
  height: 16px;
  -webkit-animation: loader-spin 1s linear infinite; /* Safari */
  animation: loader-spin 1s linear infinite;
  margin-left: 1em;
}

/* Safari */
@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
