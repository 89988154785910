.travel-entry-view {
  background-color: #f7f8fa;
  border: 1px solid #8fb1dc;
  margin: 10px 0 15px 0;
  padding: 10px;

  .entry_footer {
    text-align: right;
    font-style: italic;
    font-size: small;

    a {
      margin-right: 10px;
      padding-right: 5px;
    }
  }
}

.entry_header {
  border-bottom: 1px solid #8fb1dc;
  height: 42px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;

  .link_to_country {
    margin: 0 10px 0 5px;
  }

  .entry_title {
    font-size: large;
    font-style: italic;
    font-weight: bold;
  }

  .datetime {
    padding-left: 0;
  }
}

.entry_photos {
  display: flex;
  flex-flow: row wrap;

  $padding: 3px;
  $margin: calc($padding/2);
  $photosWidth: 800px + $padding;
  width: $photosWidth;
  margin: 0 auto;

  img {
    $border: 1px;
    $photosPerRow: 5;
    $maxWith: calc(
      $photosWidth/$photosPerRow - 2 * $padding - 2 * $border - 2 * $margin
    );

    height: calc($maxWith/1.5);
    width: $maxWith;

    background-color: white;
    border: solid $border #a9a9a9;
    padding: $padding;
    margin: $margin;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }

  ~ .travel_navigation {
    border-top: none;
    padding-top: 0;
  }
}

.travel_navigation {
  + * {
    margin-top: 10px;
  }
}
