ul.posts {
  list-style: none;
  padding: 0 0 0 15px;

  a {
    width: 100%;
  }

  li {
    margin-bottom: 5px;
  }

  a.back_to_top {
    font-size: smaller;
    font-style: italic;
    margin: 0 0 25px 0;
  }
}
